<template>
  <b-table
    :data="$report.fileSnapshots"
    :paginated="$report.fileSnapshots.length > perPage"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="true"
  >
    <template slot="empty">
      <no-results
        class="column is-12"
        icon="cog"
        title="No snapshots"
        :message="`There are no snapshots for ${$report.timeframe}.`"
      />
    </template>

    <template slot-scope="props">
      <b-table-column field="id" label="Snapshot" width="220">
        <span>{{
          $moment.unix(props.row.snapshot).format("Do MMM, YYYY @ HH:mm")
        }}</span>
      </b-table-column>

      <b-table-column field="id" label="Host">
        {{
          $report.fileBackupConfig.sshhost
            ? `${$report.fileBackupConfig.sshhost} (SSH)`
            : `${$report.fileBackupConfig.ftphost} (FTP)`
        }}
      </b-table-column>

      <b-table-column field="id" label="Size" width="120">
        <span class="has-white-space-nowrap"
          >~{{ $humanFileSize(props.row.size * 1024) }}</span
        >
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "ReportFileSnapshotsTable",
  inject: ["$reportProvider"],
  data() {
    return {
      currentPage: 1,
      perPage: 5
    };
  },
  computed: {
    $report() {
      return this.$reportProvider();
    }
  }
};
</script>
